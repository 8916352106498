import * as React from 'react';
import Select from 'react-select';
import { RouteComponentProps, Switch, Route, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import InView from 'react-intersection-observer';
import Component2 from '@reach/component-component';
import styled from 'styled-components';

import BaseLayout from '../../components/BaseLayout';
import { MapsLibLoader, AddressAutocompleteInput } from '../../components';
import { apiFetch } from '../../utils';
import { usePromise } from '../../components/usePromise';
import { gql, useQuery, useMutation } from '../../components/ApolloClient';
import { BookingStatusBadge } from '../../components/BookingStatusBadge';
import { BaseStyle } from '../../components/BaseStyle';
import { SendgridActivity } from './sendgrid-activity';
import { UUID_REGEX } from '../../constants';

const DATE_TIME_DISPLAY_FORMAT = 'MM-DD-YYYY HH:mm';

const serviceTypeOptions = [
  { label: 'Photography', value: 'photography' },
  { label: 'Videography', value: 'videography' },
];

const HeaderLinks = styled.div`
  a {
    text-decoration: none;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
    }

    &.active {
      border-bottom: 3px #1f6fff solid;
    }
  }
`;

type SelectedType = {
  label: string;
  value: string;
};

function Tools(props: { session: { token: string } }) {
  const coveragePromiser = usePromise<{
    hasCoverage?: boolean;
    count: number;
  }>();

  const coverage = coveragePromiser.result;

  const {
    session: { token },
  } = props;

  const [cursors, setCursors] = React.useState<string[]>([]);
  const [providerId, setProviderId] = React.useState<string | undefined>('');
  const [bookingId, setBookingId] = React.useState<string | undefined>('');

  const [accrualGenerationEndAt, setAccrualGenerationEndAt] = React.useState<
    string | null
  >(null);

  const [accrualEntryIdsVoid, setAccrualEntryIdsVoid] = React.useState<string>(
    ''
  );
  const [
    accrualEntryIdsVoidReason,
    setAccrualEntryIdsVoidReason,
  ] = React.useState<string>('');

  const [
    userEmailForSubscriptionCheck,
    setUserEmailForSubscriptionCheck,
  ] = React.useState<string | undefined>('');

  const [
    userEmailForRemoveSuppression,
    setUserEmailForRemoveSuppression,
  ] = React.useState<string | undefined>('');

  const [
    bookingReallocationHistory,
    setBookingReallocationHistory,
  ] = React.useState<
    { bookingStartAt?: string; allocatedAt?: string; id: string } | undefined
  >(undefined);

  const [coverageBatch, setCoverageBatch] = React.useState<string | undefined>(
    ''
  );

  const [selectedServiceType, setSelectedServiceType] = React.useState<
    SelectedType | undefined
  >(serviceTypeOptions[0]);

  const [selectedShootType, setSelectedShootType] = React.useState<
    SelectedType | undefined
  >();

  const [selectedRegion, setSelectedRegion] = React.useState<
    SelectedType | undefined
  >();

  const [selectedPartner, setSelectedPartner] = React.useState<
    SelectedType | undefined
  >();

  const triggerBatchCoveragePromise = usePromise();

  const providerInputRef = React.useRef<HTMLInputElement>(null);
  const bookingInputRef = React.useRef<HTMLInputElement>(null);

  const bookingProviderHistoryQuery = useQuery<{
    bookingById?: {
      id: string;
      timezone: string;
    };
    bookingProviderHistoryListAsAdmin?: {
      id: string;
      providerAllocatedAt?: string;
      bookingStartAt?: string;
      bookingPaidAt?: string;
      bookingDuration?: number;
      oldStatus?: string;
      newStatus?: string;
    }[];
  }>(
    gql`
      query BookingProviderHistory($bookingId: ID!, $providerId: ID!) {
        bookingById: bookingByIdAsAdmin(id: $bookingId) {
          id
          timezone
        }
        bookingProviderHistoryListAsAdmin(
          bookingId: $bookingId
          providerId: $providerId
        ) {
          id
          providerAllocatedAt
          bookingStartAt
          bookingPaidAt
          bookingDuration
          oldStatus
          newStatus
        }
      }
    `,
    {
      variables: {
        bookingId: bookingId,
        providerId: providerId,
      },
      skip:
        providerId == null ||
        providerId === '' ||
        bookingId == null ||
        bookingId === '',
    }
  );

  const shootTypesQuery = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];
  }>(gql`
    query GetPublicShootTypesForCoverageBatch {
      getPublicShootTypes {
        category
        displayName
      }
    }
  `);

  const shootTypes = shootTypesQuery.data?.getPublicShootTypes;

  const shoottypeOptions =
    shootTypes?.map((shootType) => ({
      label: shootType.displayName,
      value: shootType.category,
    })) ?? [];

  const regionsQuery = useQuery<{
    regionListAsAdmin: {
      edges: {
        uid: string;
        name: string;
      }[];
    };
  }>(gql`
    query GetRegionsForCoverageBatch {
      regionListAsAdmin {
        edges {
          uid
          name
        }
      }
    }
  `);

  const regions = regionsQuery.data?.regionListAsAdmin.edges;

  const regionOptions =
    regions?.map((region) => ({
      label: region.name,
      value: region.uid,
    })) ?? [];

  const partnersQuery = useQuery<{
    getPartnersAsAdmin?: { edges?: { uid: string; name: string }[] };
  }>(gql`
    query GetPartnersForCoverageTool {
      getPartnersAsAdmin {
        edges {
          uid
          name
        }
      }
    }
  `);

  const partnerOptions =
    partnersQuery.data?.getPartnersAsAdmin?.edges?.map((partner) => ({
      label: partner.name,
      value: partner.uid,
    })) ?? [];

  const [
    emailUserSubscriptionStatusSearch,
    {
      data: emailUserSubscriptionStatusSearchData,
      loading: emailUserSubscriptionStatusSearchLoading,
      error: emailUserSubscriptionStatusSearchError,
    },
  ] = useMutation<
    {
      emailUserSubscriptionStatusSearch?: {
        subscriptionStatus: string;
      };
    },
    { userEmail: string }
  >(
    gql`
      mutation EmailUserSubscriptionStatusSearch($userEmail: String!) {
        emailUserSubscriptionStatusSearch(input: { userEmail: $userEmail }) {
          subscriptionStatus
        }
      }
    `
  );

  const [
    emailUserSuppressionListRemove,
    {
      data: emailUserSuppressionListRemoveData,
      loading: emailUserSuppressionListRemoveLoading,
      error: emailUserSuppressionListRemoveError,
    },
  ] = useMutation<
    {
      emailUserSuppressionListRemove?: {
        succeed: boolean;
      };
    },
    { userEmail: string }
  >(
    gql`
      mutation EmailUserSuppressionListRemove($userEmail: String!) {
        emailUserSuppressionListRemove(input: { userEmail: $userEmail }) {
          succeed
        }
      }
    `
  );

  const [
    generateAccrualAccountingBalancesAsAdmin,
    {
      data: generateAccrualAccountingBalancesAsAdminData,
      loading: generateAccrualAccountingBalancesAsAdminLoading,
      error: generateAccrualAccountingBalancesAsAdminError,
    },
  ] = useMutation<
    {
      generateAccrualAccountingBalancesAsAdmin?: {
        success: boolean;
      };
    },
    {
      endAt: string | null;
    }
  >(
    gql`
      mutation GenerateAccrualAccountingBalancesAsAdminFromTools(
        $endAt: String
      ) {
        generateAccrualAccountingBalancesAsAdmin(input: { endAt: $endAt }) {
          success
        }
      }
    `
  );

  const [accrualEntryListVoid, accrualEntryListVoidMutation] = useMutation<
    {
      generateAccrualAccountingBalancesAsAdmin?: {
        success: boolean;
      };
    },
    {
      accrualEntryIds: string[];
      reason: string;
    }
  >(
    gql`
      mutation AccrualEntryListVoidAsAdmin(
        $accrualEntryIds: [ID!]!
        $reason: String!
      ) {
        accrualEntryListVoidAsAdmin(
          input: { accrualEntryIds: $accrualEntryIds, reason: $reason }
        ) {
          success
        }
      }
    `
  );

  const [
    leadCreationTriggerAsAdmin,
    leadCreationTriggerAsAdminMutation,
  ] = useMutation<
    {
      leadCreationTriggerAsAdmin?: {
        validated: number;
        errors: number;
      };
    },
    {
      bookingIdList: string[];
      tags?: string[];
    }
  >(
    gql`
      mutation LeadCreationTriggerAsAdmin(
        $bookingIdList: [ID!]!
        $tags: [String!]
      ) {
        leadCreationTriggerAsAdmin(
          input: { bookingIdList: $bookingIdList, tags: $tags }
        ) {
          validated
          errors
        }
      }
    `
  );

  const triggerBatchCoverage = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    triggerBatchCoveragePromise.setPromise(
      apiFetch(`/api/v2/admin/bookings/coverage-tool`, {
        method: 'POST',
        body: JSON.stringify({
          regionSlug: selectedRegion?.label,
          serviceType: selectedServiceType?.value,
          shootType: selectedShootType?.value,
          partnerId: selectedPartner?.value,
        }),
        token,
      }).then((result: { success: boolean; message: string }) =>
        setCoverageBatch(result.message)
      )
    );
  };

  const bookingProviderHistory =
    bookingProviderHistoryQuery.data?.bookingProviderHistoryListAsAdmin;

  const bookingTimezone =
    bookingProviderHistoryQuery.data?.bookingById?.timezone;

  const isEmailToRemoveUnsubscribeEmpty =
    userEmailForRemoveSuppression == null ||
    userEmailForRemoveSuppression.length === 0;

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div
            className="card border-0 h-100"
            style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
          >
            <div className="card-body">
              <h4>Address Lookup</h4>
              <MapsLibLoader>
                {({ maps: mapsLib, loading: loadingMaps }) => (
                  <div className="form-group mb-0">
                    {loadingMaps ? (
                      <input
                        type="text"
                        className="form-control"
                        value="Loading maps lib..."
                        disabled
                      />
                    ) : (
                      <AddressAutocompleteInput
                        onPlaceSelected={({ coords, address }) => {
                          coveragePromiser.setPromise(
                            apiFetch<{
                              results: {}[];
                              count: number;
                            }>('/api/v2/providers/coverage', {
                              token,
                              query: {
                                lat: coords[0],
                                lng: coords[1],
                                address,
                              },
                            }).then((res) => ({
                              ...res,
                              hasCoverage: res.results.length > 0,
                            }))
                          );
                        }}
                        mapsLib={mapsLib}
                        type="text"
                        className={`form-control ${
                          coveragePromiser.promise &&
                          !coveragePromiser.isPending
                            ? coverage?.hasCoverage
                              ? 'is-valid'
                              : 'is-invalid'
                            : ''
                        }`}
                        placeholder="Address"
                        autoComplete="shipping street-address"
                        disabled={coveragePromiser.isPending}
                      />
                    )}
                  </div>
                )}
              </MapsLibLoader>
              {coveragePromiser.promise != null &&
              coveragePromiser.isPending === false ? (
                coveragePromiser.result?.hasCoverage === true ? (
                  <div className="alert alert-success mb-0 mt-3">
                    The area has {coveragePromiser.result?.count} providers.
                  </div>
                ) : (
                  <div className="alert alert-danger mb-0 mt-3">
                    {"We don't have coverage in the area."}
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div
            className="card border-0"
            style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
          >
            <div className="card-body">
              <h4>Coverage requests</h4>

              <form onSubmit={triggerBatchCoverage}>
                <div className="form-row">
                  <div className="form-group col tw-m-0">
                    <label>Region slug</label>
                    <Select
                      value={selectedRegion}
                      options={regionOptions}
                      placeholder="Region slug"
                      isLoading={regionsQuery.loading}
                      isDisabled={triggerBatchCoveragePromise.isPending}
                      onChange={(selected: SelectedType) =>
                        setSelectedRegion(selected)
                      }
                    />
                  </div>

                  <div className="form-group col tw-m-0">
                    <label>Service type</label>
                    <Select
                      value={selectedServiceType}
                      options={serviceTypeOptions}
                      placeholder="Service type"
                      onChange={(selected: SelectedType) =>
                        setSelectedServiceType(selected)
                      }
                    />
                  </div>

                  <div className="form-group col tw-m-0">
                    <label>Shoot type</label>
                    <Select
                      value={selectedShootType}
                      options={shoottypeOptions}
                      placeholder="Shoot type"
                      isLoading={shootTypesQuery.loading}
                      isDisabled={triggerBatchCoveragePromise.isPending}
                      onChange={(selected: SelectedType) =>
                        setSelectedShootType(selected)
                      }
                    />
                  </div>

                  <div className="form-group col tw-m-0">
                    <label>Partner (optional)</label>
                    <Select
                      value={selectedPartner}
                      options={partnerOptions}
                      placeholder="Partner"
                      isLoading={partnersQuery.loading}
                      isDisabled={triggerBatchCoveragePromise.isPending}
                      isClearable
                      onChange={(selected: SelectedType) =>
                        setSelectedPartner(selected)
                      }
                    />
                    <small className="form-text text-muted">
                      Used by the Creator Onboarding team
                    </small>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col tw-m-0 tw-flex tw-items-center tw-space-x-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        triggerBatchCoveragePromise.isPending ||
                        triggerBatchCoveragePromise.error != null ||
                        shootTypesQuery.loading ||
                        regionsQuery.loading ||
                        selectedRegion == null
                      }
                    >
                      Submit
                    </button>

                    {(coverageBatch ?? '') !== '' ? (
                      <span>{coverageBatch}</span>
                    ) : null}

                    {triggerBatchCoveragePromise.error != null && (
                      <span className="alert alert-danger">
                        Error starting the coverage job
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-12">
              <h4>Debug calendar sync exceptions</h4>

              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  setProviderId(providerInputRef.current?.value);
                  setBookingId(bookingInputRef.current?.value);
                }}
              >
                <div className="form-row align-items-end">
                  <div className="form-group col-3">
                    <label>Provider ID</label>
                    <input
                      defaultValue={providerId}
                      type="text"
                      className="form-control"
                      placeholder="Provider ID"
                      ref={providerInputRef}
                    />
                  </div>

                  <div className="form-group col-3">
                    <label>Booking ID</label>
                    <input
                      defaultValue={bookingId}
                      type="text"
                      className="form-control"
                      placeholder="Booking ID"
                      ref={bookingInputRef}
                    />
                  </div>

                  <div className="form-group col">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={bookingProviderHistoryQuery.loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {bookingProviderHistoryQuery.loading === false &&
            bookingProviderHistoryQuery.data != null && (
              <>
                <div className="row">
                  <div className="col-12">
                    <h5>Times photographer was changed in the booking</h5>
                    <p>
                      <small>
                        {bookingTimezone != null
                          ? `*All dates are in the booking timezone: ${moment()
                              .tz(bookingTimezone)
                              .format('z (Z)')}`
                          : `*All dates are in local timezone: ${moment().format(
                              'z (Z)'
                            )}`}
                      </small>
                    </p>
                  </div>

                  <div className="col-12">
                    {bookingProviderHistory != null ? (
                      <div
                        className="table-responsive flex-1"
                        style={{
                          maxHeight: 200,
                        }}
                      >
                        <div className="rounded bg-white">
                          <table className="table table-hover table-sm">
                            <thead>
                              <tr>
                                <th>Old booking status</th>
                                <th>New booking status</th>
                                <th>Paid</th>
                                <th>Start</th>
                                <th>Duration</th>
                                <th>PG allocated</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody style={{ overflow: 'scroll' }}>
                              {bookingProviderHistory.map((bookingHistory) => (
                                <tr key={bookingHistory.id}>
                                  <td>
                                    <BookingStatusBadge
                                      status={bookingHistory.oldStatus}
                                    />
                                  </td>
                                  <td>
                                    <BookingStatusBadge
                                      status={bookingHistory.newStatus}
                                    />
                                  </td>
                                  <td
                                    title={moment(
                                      bookingHistory.bookingPaidAt
                                    ).toISOString()}
                                  >
                                    {bookingTimezone != null
                                      ? moment(bookingHistory.bookingPaidAt)
                                          .tz(bookingTimezone)
                                          .format(DATE_TIME_DISPLAY_FORMAT)
                                      : moment(
                                          bookingHistory.bookingPaidAt
                                        ).format(DATE_TIME_DISPLAY_FORMAT)}
                                  </td>
                                  <td
                                    title={moment(
                                      bookingHistory.bookingStartAt
                                    ).toISOString()}
                                  >
                                    {bookingTimezone != null
                                      ? moment(bookingHistory.bookingStartAt)
                                          .tz(bookingTimezone)
                                          .format(DATE_TIME_DISPLAY_FORMAT)
                                      : moment(
                                          bookingHistory.bookingStartAt
                                        ).format(DATE_TIME_DISPLAY_FORMAT)}
                                  </td>
                                  <td
                                    title={bookingHistory.bookingDuration?.toString()}
                                  >
                                    {bookingHistory.bookingDuration == null
                                      ? '-'
                                      : bookingHistory.bookingDuration <= 60
                                      ? `${bookingHistory.bookingDuration} minutes`
                                      : bookingHistory.bookingDuration === 60
                                      ? '1 hour'
                                      : `${(
                                          bookingHistory.bookingDuration / 60
                                        ).toFixed(1)} hours`}
                                  </td>
                                  <td
                                    title={moment(
                                      bookingHistory.providerAllocatedAt
                                    ).toISOString()}
                                  >
                                    {bookingTimezone != null
                                      ? moment(
                                          bookingHistory.providerAllocatedAt
                                        )
                                          .tz(bookingTimezone)
                                          .format(DATE_TIME_DISPLAY_FORMAT)
                                      : moment(
                                          bookingHistory.providerAllocatedAt
                                        ).format(DATE_TIME_DISPLAY_FORMAT)}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        setBookingReallocationHistory(
                                          bookingHistory
                                        )
                                      }
                                    >
                                      Filter exceptions
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h5>External exceptions around the time of the shoot</h5>
                    <p className="font-weight-bold">
                      <small>
                        {bookingTimezone != null
                          ? `*All dates are in the booking timezone: ${moment()
                              .tz(bookingTimezone)
                              .format('z (Z)')}`
                          : `*All dates are in local timezone: ${moment().format(
                              'z (Z)'
                            )}`}
                      </small>
                    </p>
                  </div>

                  <div
                    className="col-12 table-responsive flex-1"
                    style={{
                      position: 'relative',
                      maxHeight: 400,
                      overflow: 'scroll',
                    }}
                  >
                    <table
                      className="table table-hover table-sm"
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: 0,
                      }}
                    >
                      <thead>
                        <tr>
                          {[
                            'Summary',
                            'Marked as',
                            'Start',
                            'End',
                            'Created',
                            'Synced',
                            'Archived',
                          ].map((header) => (
                            <th
                              style={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: '#FFF',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '2px solid #dee2e6',
                              }}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {[null, ...cursors].map((cursor, index) => (
                          <ExceptionRows
                            key={cursor ?? '0'}
                            cursor={cursor}
                            timezone={bookingTimezone}
                            onMore={
                              index === cursors.length + 1 - 1
                                ? ({ nextCursor }) =>
                                    setCursors((state) =>
                                      state.includes(nextCursor)
                                        ? state
                                        : [...state, nextCursor]
                                    )
                                : undefined
                            }
                            providerId={providerId}
                            bookingReallocationHistory={
                              bookingReallocationHistory
                            }
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>

      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <div className="card-body">
          <h4>Sendgrid Subscription status Lookup</h4>

          <div className="form-row align-items-end">
            <div className="form-group">
              <label>Enter the user email with no spaces</label>
              <input
                type="text"
                className="form-control"
                placeholder="user@domain.com"
                onChange={(e) =>
                  setUserEmailForSubscriptionCheck(e.target.value)
                }
              />
            </div>

            <div className="form-group col">
              <button
                type="button"
                className="btn btn-primary"
                disabled={emailUserSubscriptionStatusSearchLoading}
                onClick={async () => {
                  userEmailForSubscriptionCheck != null &&
                    userEmailForSubscriptionCheck.length > 0 &&
                    (await emailUserSubscriptionStatusSearch({
                      variables: {
                        userEmail: userEmailForSubscriptionCheck,
                      },
                    }));
                }}
              >
                Search
              </button>
            </div>
          </div>

          <div>
            {emailUserSubscriptionStatusSearchError != null ? (
              <div className="text-danger">
                Error searching for the subscription status
              </div>
            ) : (
              <React.Fragment>
                {emailUserSubscriptionStatusSearchData != null && (
                  <React.Fragment>
                    <span className="font-weight-bold">User status: </span>
                    {
                      emailUserSubscriptionStatusSearchData
                        ?.emailUserSubscriptionStatusSearch?.subscriptionStatus
                    }
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <div className="card-body">
          <h4>Sendgrid Subscription remove unsubscribed email</h4>

          <div className="form-row align-items-end">
            <div className="form-group">
              <label>Enter the user email with no spaces.</label>
              <input
                type="text"
                className="form-control"
                placeholder="user@domain.com"
                onChange={(e) =>
                  setUserEmailForRemoveSuppression(e.target.value)
                }
              />
            </div>

            <div className="form-group col">
              <button
                type="button"
                className="btn btn-danger"
                disabled={emailUserSuppressionListRemoveLoading}
                onClick={async () => {
                  if (isEmailToRemoveUnsubscribeEmpty) {
                    return;
                  }

                  const shouldContinue = window.confirm(
                    `Are you sure you want to remove ${userEmailForRemoveSuppression} from the email suppression list`
                  );

                  if (shouldContinue) {
                    await emailUserSuppressionListRemove({
                      variables: {
                        userEmail: userEmailForRemoveSuppression,
                      },
                    });
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>

          <div>
            {emailUserSuppressionListRemoveError != null ? (
              <div className="text-danger">
                The system could to unsubscribe the email or you do not have
                permission to perform this operation.
              </div>
            ) : (
              <React.Fragment>
                {emailUserSuppressionListRemoveData != null &&
                  isEmailToRemoveUnsubscribeEmpty === false && (
                    <React.Fragment>
                      <span className="font-weight-bold">Response: </span>

                      {emailUserSuppressionListRemoveData
                        ?.emailUserSuppressionListRemove?.succeed === true &&
                        'The email was removed from the suppression list (in case of it being suppressed).'}
                    </React.Fragment>
                  )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <form
          className="card-body col-md-5"
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();

            const target = e.target as typeof e.target & {
              leadCreationTriggerBookingIds: { value: string };
              leadCreationTriggerTags: { value: string };
            };

            if (target.leadCreationTriggerBookingIds.value === '') return;

            const bookingIdListRaw = target.leadCreationTriggerBookingIds.value.split(
              '\n'
            );
            const bookingIdList = bookingIdListRaw.filter((id) =>
              new RegExp(UUID_REGEX).test(id)
            );

            if (bookingIdList.length === 0) {
              window.alert('No valid booking IDs found');
              return;
            }

            const tagsRaw = target.leadCreationTriggerTags.value;
            const tags =
              tagsRaw !== ''
                ? tagsRaw.split(',').map((tag) => tag.trim())
                : undefined;

            const shouldContinue = window.confirm(
              `Are you sure you want to trigger the lead creation for ${bookingIdList.length} bookings?`
            );

            if (shouldContinue === false) return;

            leadCreationTriggerAsAdmin({
              variables: { bookingIdList, tags },
            });
          }}
        >
          <h4>Manual lead creation trigger by bookingId</h4>

          <div className="form-group">
            <label htmlFor="leadCreationTriggerBookingIds">Booking IDs</label>
            <textarea
              name="leadCreationTriggerBookingIds"
              className="form-control"
              placeholder="Booking IDs"
              rows={3}
              required
              disabled={leadCreationTriggerAsAdminMutation.loading}
            />
            <small className="form-text text-muted">
              Enter the booking IDs separated by a newline.
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="leadCreationTriggerTags">Tags (optional)</label>
            <input
              name="leadCreationTriggerTags"
              type="text"
              className="form-control"
              disabled={leadCreationTriggerAsAdminMutation.loading}
            />
            <small className="form-text text-muted">
              Enter the tags separated by comma.
            </small>
          </div>

          {leadCreationTriggerAsAdminMutation.error != null && (
            <div className="text-danger">A problem has ocurred</div>
          )}

          <button
            type="submit"
            className="btn btn-danger"
            disabled={leadCreationTriggerAsAdminMutation.loading}
          >
            Trigger leads creation
          </button>

          {leadCreationTriggerAsAdminMutation.data != null && (
            <div className="mt-3 d-flex tw-space-x-3">
              <p className="m-0 text-primary">
                Valid bookings:{' '}
                {
                  leadCreationTriggerAsAdminMutation.data
                    .leadCreationTriggerAsAdmin?.validated
                }
              </p>
              <p className="m-0 text-danger">
                Invalid bookings:{' '}
                {
                  leadCreationTriggerAsAdminMutation.data
                    .leadCreationTriggerAsAdmin?.errors
                }
              </p>
            </div>
          )}
        </form>
      </div>

      <div className="row">
        <div className="col-sm">
          <div
            className="card mt-3 border-0"
            style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
          >
            <div className="card-body">
              <h4>Accrual accounting balances generation tool</h4>

              <p>
                The results could take ~5 minutes to appear in the report as a
                new sheet at the end
              </p>
              <div className="form align-items-end">
                <div className="form-row">
                  <input
                    type="date"
                    onChange={(e) => setAccrualGenerationEndAt(e.target.value)}
                    value={accrualGenerationEndAt ?? ''}
                  />
                </div>

                <div style={{ height: 16 }}></div>

                <div className="form-row">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn-primary btn"
                      onClick={async () =>
                        window.open(
                          'https://docs.google.com/spreadsheets/d/1muzhV1XS-b0uqKCf3zrr_V5oWb1tTvsoDbbM6_VBdho/edit#gid=2108898179'
                        )
                      }
                    >
                      See report
                    </button>
                  </div>

                  <div className="form-group col">
                    <button
                      type="button"
                      className="btn btn-danger"
                      disabled={generateAccrualAccountingBalancesAsAdminLoading}
                      onClick={async () => {
                        const shouldContinue = window.confirm(
                          `Are you sure you want to generate the accounting balances?`
                        );

                        if (shouldContinue) {
                          await generateAccrualAccountingBalancesAsAdmin({
                            variables: {
                              endAt:
                                accrualGenerationEndAt != null
                                  ? moment(accrualGenerationEndAt).format()
                                  : null,
                            },
                          });
                        }
                      }}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>

              <div>
                {generateAccrualAccountingBalancesAsAdminError != null ? (
                  <div className="text-danger">
                    Error generating the accounting balances, please try again
                    or contact @dev-team
                  </div>
                ) : (
                  <React.Fragment>
                    {generateAccrualAccountingBalancesAsAdminData?.generateAccrualAccountingBalancesAsAdmin !=
                      null && (
                      <React.Fragment>
                        <span className="font-weight-bold">Response: </span>

                        {generateAccrualAccountingBalancesAsAdminData
                          ?.generateAccrualAccountingBalancesAsAdmin
                          ?.success === true &&
                          'Please wait ~5 minutes for the report to be generated.'}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm">
          <div
            className="card mt-3 border-0"
            style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
          >
            <form
              className="card-body"
              onSubmit={(e) => {
                e.preventDefault();

                const accrualEntryIds = accrualEntryIdsVoid.split(',');

                accrualEntryListVoid({
                  variables: {
                    accrualEntryIds,
                    reason: accrualEntryIdsVoidReason,
                  },
                });
              }}
            >
              <h4>Accrual entries void tool</h4>

              <div className="form-group">
                <label htmlFor="accrualEntriesVoidIDList">
                  Accrual entry IDs*
                </label>
                <input
                  type="text"
                  id="accrualEntriesVoidIDList"
                  className="form-control"
                  value={accrualEntryIdsVoid}
                  onChange={(e) => setAccrualEntryIdsVoid(e.target.value)}
                />
                <small className="form-text text-muted">
                  Insert a list of accrual entry IDs separated by a comma.
                </small>
              </div>

              <div className="form-group">
                <label htmlFor="accrualEntriesVoidReason">Reason*</label>
                <input
                  type="text"
                  id="accrualEntriesVoidReason"
                  className="form-control"
                  value={accrualEntryIdsVoidReason}
                  onChange={(e) => setAccrualEntryIdsVoidReason(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  accrualEntryIdsVoid === '' ||
                  accrualEntryIdsVoidReason === '' ||
                  accrualEntryListVoidMutation.loading === true
                }
              >
                Void entries
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const ExceptionRows = ({
  onMore,
  cursor,
  providerId,
  bookingReallocationHistory,
  timezone,
}: {
  onMore?: (p: { nextCursor: string }) => void;
  cursor?: string | null;
  providerId?: string;
  bookingReallocationHistory?: {
    bookingStartAt?: string;
    allocatedAt?: string;
    id: string;
  };
  timezone?: string;
}) => {
  const providerExternalExceptionsQuery = useQuery<{
    providerById?: {
      calendarSyncExceptionsAsAdmin?: {
        edges?: {
          id: string;
          summary?: string;
          blockAvailability?: boolean;
          startAt?: string;
          endAt?: string;
          archivedAt?: string;
          updatedAt?: string;
          calendarEventCreatedAt?: string;
        }[];
        cursor?: string;
      };
    };
  }>(
    gql`
      query ProviderExternalExceptions(
        $id: ID!
        $first: Int
        $cursor: String
        $bookingStartAt: String
        $providerAllocatedAt: String
      ) {
        providerById(id: $id) {
          id
          calendarSyncExceptionsAsAdmin(
            first: $first
            cursor: $cursor
            bookingStartAt: $bookingStartAt
            providerAllocatedAt: $providerAllocatedAt
          ) {
            edges {
              id
              summary
              blockAvailability
              startAt
              endAt
              archivedAt
              updatedAt
              calendarEventCreatedAt
            }
            cursor
          }
        }
      }
    `,
    {
      variables: {
        id: providerId,
        cursor,
        first: cursor == null ? 20 : 40,
        bookingStartAt: bookingReallocationHistory?.bookingStartAt,
        providerAllocatedAt: bookingReallocationHistory?.allocatedAt,
      },
      skip:
        providerId == null ||
        providerId === '' ||
        bookingReallocationHistory?.bookingStartAt === '' ||
        bookingReallocationHistory?.allocatedAt === '',
    }
  );

  const providerExternalExceptions =
    providerExternalExceptionsQuery.data?.providerById
      ?.calendarSyncExceptionsAsAdmin?.edges;

  const loadingProviderExceptions = providerExternalExceptionsQuery.loading;

  const nextCursor =
    providerExternalExceptionsQuery.data?.providerById
      ?.calendarSyncExceptionsAsAdmin?.cursor;

  React.useEffect(() => {
    if (
      bookingReallocationHistory?.bookingStartAt != null ||
      bookingReallocationHistory?.bookingStartAt !== '' ||
      bookingReallocationHistory?.allocatedAt != null ||
      bookingReallocationHistory?.allocatedAt !== ''
    ) {
      providerExternalExceptionsQuery.refetch();
    }
  }, [bookingReallocationHistory, providerExternalExceptionsQuery]);

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {loadingProviderExceptions === true
        ? Array.from(Array(7)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : providerExternalExceptions?.map((exception) => (
            <tr key={exception.id}>
              <td>{exception.summary}</td>

              <td>
                <span
                  className="badge badge-primary text-uppercase"
                  style={{
                    background:
                      exception.blockAvailability === true
                        ? '#FFC300'
                        : '#2FB67D',
                  }}
                >
                  {exception.blockAvailability === true ? 'Busy' : 'Free'}
                </span>
              </td>

              <td
                title={
                  exception.startAt == null
                    ? undefined
                    : moment(exception.startAt).toISOString()
                }
              >
                {exception.startAt != null && timezone != null
                  ? moment(exception.startAt)
                      .tz(timezone)
                      .format(DATE_TIME_DISPLAY_FORMAT)
                  : moment(exception.startAt).format(DATE_TIME_DISPLAY_FORMAT)}
              </td>

              <td
                title={
                  exception.endAt == null
                    ? undefined
                    : moment(exception.endAt).toISOString()
                }
              >
                {exception.endAt != null && timezone != null
                  ? moment(exception.endAt)
                      .tz(timezone)
                      .format(DATE_TIME_DISPLAY_FORMAT)
                  : moment(exception.endAt).format(DATE_TIME_DISPLAY_FORMAT)}
              </td>

              <td
                title={
                  exception.calendarEventCreatedAt == null
                    ? undefined
                    : moment(exception.calendarEventCreatedAt).toISOString()
                }
              >
                {exception.calendarEventCreatedAt != null && timezone != null
                  ? moment(exception.calendarEventCreatedAt)
                      .tz(timezone)
                      .format(DATE_TIME_DISPLAY_FORMAT)
                  : moment(exception.calendarEventCreatedAt).format(
                      DATE_TIME_DISPLAY_FORMAT
                    )}
              </td>

              <td
                title={
                  exception.updatedAt == null
                    ? undefined
                    : moment(exception.updatedAt).toISOString()
                }
              >
                {exception.updatedAt != null && timezone != null
                  ? moment(exception.updatedAt)
                      .tz(timezone)
                      .format(DATE_TIME_DISPLAY_FORMAT)
                  : moment(exception.updatedAt).format(
                      DATE_TIME_DISPLAY_FORMAT
                    )}
              </td>

              <td
                title={
                  exception.archivedAt == null
                    ? undefined
                    : moment(exception.archivedAt).toISOString()
                }
              >
                {exception.archivedAt == null
                  ? '-'
                  : timezone != null
                  ? moment(exception.archivedAt)
                      .tz(timezone)
                      .format(DATE_TIME_DISPLAY_FORMAT)
                  : moment(exception.archivedAt).format(
                      DATE_TIME_DISPLAY_FORMAT
                    )}
              </td>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
};

export function ToolsRoute({
  session,
  match,
  match: { path: basePath },
}: {
  session: { token: string };
} & RouteComponentProps) {
  return (
    <BaseLayout session={session} match={match}>
      <BaseStyle className="d-flex flex-column flex-1">
        <HeaderLinks className="d-flex">
          <NavLink exact to={basePath}>
            <h2>Tools</h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            |
          </h2>

          <NavLink to={`${basePath}/sendgrid-activity`}>
            <h2>Sendgrid activity</h2>
          </NavLink>
        </HeaderLinks>
        <Switch>
          <Route
            exact
            path={basePath}
            render={(props) => <Tools session={session} />}
          />

          <Route
            path={`${basePath}/sendgrid-activity`}
            render={(props) => <SendgridActivity {...props} />}
          />
        </Switch>
      </BaseStyle>
    </BaseLayout>
  );
}
